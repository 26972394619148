import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  
  constructor(private socket: Socket, private gtmService: GoogleTagManagerService) { }

  sendEvent() {
    this.socket.emit('eventName');
  }

  // Écouter un événement
  ngOnInit() {
    console.log("Initialization socket", this.socket);
    this.socket.on('tracking', (dataTag) => {
      console.log("let's push the data tag :", dataTag);
      this.gtmService.pushTag(dataTag);
    });
  }

  title = 'frontend';
}
